import React from 'react';
import { makeStyles  } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { HeroShaped } from 'components/organisms';
import desktopGeneric from '../../../../assets/images/desktop-generic.svg';

const useStyles = makeStyles(theme => ({
  cover: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  }
}));

const Hero = () => {
  const classes = useStyles();
  return (
    <div>
      <HeroShaped
        leftSide={
          <SectionHeader
            title="Features"
            align="left"
            disableGutter
            data-aos="fade-up"
            titleVariant="h1"
            titleProps={{
              color: 'primary'
            }}
          />
        }
        rightSide={
          <div className={classes.cover}>
            <Image
              src={desktopGeneric}
              alt="..."
              className={classes.coverImg}
              data-aos="fade-up"
              lazy={false}
            />
          </div>
        }
      />
    </div>
  );
};

export default Hero;
