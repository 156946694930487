import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  SectionHeader } from 'components/molecules';
import {Box, Grid, Typography} from '@material-ui/core';
import { CardBase } from 'components/organisms';
import Trademarked from '../../../../components/atoms/Trademarked';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  text: {
    marginTop: '1.5rem',
    textAlign: 'center'
  },
  cardText: {
    color: theme.palette.primary.contrastText,
    fontWeight: 900
  },
  blue: {
    backgroundColor: theme.palette.accent.blue
  },
  blueText: {
    color: theme.palette.accent.blue
  },
  green: {
    backgroundColor: theme.palette.accent.green
  },
  greenText: {
    color: theme.palette.accent.green
  },
  red: {
    backgroundColor: theme.palette.accent.red
  },
  redText: {
    color: theme.palette.accent.red
  },
  yellow: {
    backgroundColor: theme.palette.accent.yellow
  },
  yellowText: {
    color: theme.palette.accent.yellow
  }
}));

const AccomplishmentTrackers = ({ className, ...rest }) => {
  const classes = useStyles();
  const title = "Accomplishment Trackers";
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={title}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      
      <Typography component="p" className={classes.text}>
        <Trademarked>Formigio</Trademarked> allows to create tracker documents that allow you to define what you are trying to do, and help you track your progress along the way.
      </Typography>
  
      <Typography component="p" className={classes.text}>
        Following the principles of the Accomplishment System, each tracker document has areas for you to record your dream, things you learn, plan the next few steps along the way, and then record your progress towards your goals.
      </Typography>
      <Box marginBottom={2} marginTop={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.blue}>
              <Typography variant="h6" component="p" className={classes.cardText}>1) Dream</Typography>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.yellow}>
              <Typography variant="h6" component="p" className={classes.cardText}>2) Learn</Typography>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.red}>
              <Typography variant="h6" component="p" className={classes.cardText}>3) Plan</Typography>
            </CardBase>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBase withShadow className={classes.green}>
              <Typography variant="h6" component="p" className={classes.cardText}>4) Do</Typography>
            </CardBase>
          </Grid>
        </Grid>
      </Box>


    </div>
  );
};

AccomplishmentTrackers.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default AccomplishmentTrackers;
