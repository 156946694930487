import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  SectionHeader } from 'components/molecules';
import {Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography} from '@material-ui/core';
import {Trademarked} from '../../../../components/atoms';

const useStyles = makeStyles(theme => ({
  fontWeight900: {
    fontWeight: 900,
  },
  text: {
    marginTop: '1.5rem',
    textAlign: 'center'
  },
  blue: {
    backgroundColor: theme.palette.accent.blue
  }
}));

const HomePage = ({ className, ...rest }) => {
  const classes = useStyles();
  const title = "Home Page";
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={title}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      
      <Typography component="p" className={classes.text}>
        Your Home Page has all your daily work laid out.
      </Typography>
      <List style={{maxWidth: 500, margin: 'auto'}}>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.blue}>
              <Typography component="span">1</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            A calendar view where all your commitments are show as time boxes for the day.
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.blue}>
              <Typography component="span">2</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            List of trackers to review
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.blue}>
              <Typography component="span">2</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            List of the active commitments
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.blue}>
              <Typography component="span">3</Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            List of the future commitments
          </ListItemText>
        </ListItem>
      </List>
    </div>
  );
};

HomePage.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default HomePage;
