import React from 'react';
import { Section, SectionAlternate } from 'components/organisms';
import {
  AccomplishmentTrackers,
  Commitments,
  Hero,
  Milestones,
  ReviewCycle,
  Journal,
  HomePage,
  ListView, DataSync,
} from './components';
import { Divider } from '@material-ui/core';
import {Helmet} from 'react-helmet';

const Features = () => (
  <div>
    <Helmet>
      <title>Formigio (tm) - Learn the features and system that can help you plan your day and track your goals</title>
    </Helmet>
  
    <Hero />
    <Section>
      <AccomplishmentTrackers />
    </Section>
    <SectionAlternate>
      <ReviewCycle />
    </SectionAlternate>
    <Section >
      <Milestones />
    </Section>
    <Divider />
    <Section>
      <Commitments />
    </Section>
    <SectionAlternate>
      <Journal />
    </SectionAlternate>
    <Section>
      <HomePage />
    </Section>
    <Divider />
    <Section>
      <ListView />
    </Section>
    <SectionAlternate>
      <DataSync />
    </SectionAlternate>
  </div>
);

export default Features
