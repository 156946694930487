import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';
import {Trademarked} from '../../../../components/atoms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  text: {
    marginTop: '1.5rem',
    textAlign: 'center'
  }
}));

const DataSync = ({ className, ...rest }) => {
  const classes = useStyles();
  const title = "Data Sync";
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={title}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      
      <Typography component="p" className={classes.text}>
        <Trademarked>Formigio</Trademarked> is fully equipped to sync data so that you can use the web app and your mobile devices to work on your dreams no matter where you are.
      </Typography>
    </div>
  );
};

DataSync.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default DataSync;
