import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {  SectionHeader } from 'components/molecules';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
  text: {
    marginTop: '1.5rem',
    textAlign: 'center'
  }
}));

const Journal = ({ className, ...rest }) => {
  const classes = useStyles();
  const title = "Journal";
  return (
    <div className={className} {...rest}>
      <SectionHeader
        title={title}
        align="center"
        titleProps={{
          variant: 'h2',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      
      <Typography component="p" className={classes.text}>
        For each tracker you can keep a log of what worked or what was attempted. Or simply make notes on a given day.
      </Typography>
    </div>
  );
};

Journal.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Journal;
